import { GET_ALL_POLICIES } from '../policies/all/graphql/policies';
import { filters } from './modules/overview/data/filters';
import { rowConfig } from './modules/overview/data/tableData';
import { TableHeaders } from './modules/overview/data/tableHeaders';
import { GET_CLAIM_ASSESSMENTS } from './modules/overview/graphql/queries';
import { ClaimAssessmentsTemplateData } from './template/models';

export const ClaimAssessmentsData: ClaimAssessmentsTemplateData = {
  // GraphQL -----------------------------------------
  queries: {
    fetchAllClaimAssessments: GET_CLAIM_ASSESSMENTS,
    fetchAllPolicies: GET_ALL_POLICIES,
  },

  mutations: {},

  // Features ----------------------------------------
  overview: {
    headers: TableHeaders,
    filterConfig: filters,
    rowConfig,
  },
};
