import { useTranslation } from '@getpopsure/i18n-react';
import { Disclosure } from '@headlessui/react';
import {
  BriefcaseIcon,
  LogoutIcon,
  MenuIcon,
  UserIcon,
} from '@heroicons/react/outline';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import Logo from 'components/Logo';
import SelectMenu, { SelectMenuOption } from 'components/SelectMenu';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import Page403 from 'pages/errors/403';
import { retrieveHRPoliciesOverviewPath } from 'pages/policies/template/utils';
import { SVGProps, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'routes/definedPaths';
import {
  countries,
  Country,
  countryIconMapper,
  countryNameMapper,
} from 'shared/countries/models';

import { adminLinks } from './links/admin';
import { germanyClaimAssessmentsLinks } from './links/claimAssessments';
import {
  createRegionalClaimsLinks,
  germanyClaimsLinks,
  spainClaimsLink,
} from './links/claims';
import { customersLinks } from './links/customers';
import { issuesLinks } from './links/issues';
import { paymentsLinks, spainPaymentsLinks } from './links/payments';
import {
  francePoliciesLinks,
  germanyPoliciesLinks,
  regionPolicyLinksMapper,
  spainPoliciesLinks,
} from './links/policies';
import { recordsLinks, recordsLinksDE } from './links/records';
import * as styles from './styles';

export interface NavigationLink {
  name: string;
  id: string;
  icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  href?: string;
  children?: NavigationLinkChild[];
}

export interface NavigationLinkChild {
  name: string;
  id: string;
  href: string;
}

interface NavBarProps {
  current: string;
}

const NavBar = ({ current }: NavBarProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { value } = useStateWithLocalStorage('userInformation');
  const [open, setOpen] = useState(false);
  const isReferralEngine = useFlag('admin_panel_referral_engine');
  const isPrivateHealthHrEnabled = useFlag('admin_panel_private_health_hr');

  if (isReferralEngine && adminLinks.children) {
    if (
      adminLinks.children.some(
        (child) => child.id === 'admin-referral-codes'
      ) === false
    ) {
      adminLinks.children.push(
        {
          name: 'Referral codes',
          href: paths.referralCodes.path,
          id: 'admin-referral-codes',
        },
        {
          name: 'Referral campaigns',
          href: paths.referralCampaigns.path,
          id: 'admin-referral-campaigns',
        }
      );
    }
  }

  const countryOptions: SelectMenuOption<Country>[] = countries.map(
    (country) => ({
      id: country,
      label: countryNameMapper[country],
      icon: countryIconMapper[country],
    })
  );

  const [country, setCountry] = useState<Country>(
    (localStorage.getItem('country') as Country) ?? 'de'
  );

  useEffect(() => {
    window.addEventListener('storage', (e) => {
      setCountry(localStorage.getItem('country') as Country);
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('country', country);
  }, [country]);

  if (!value) {
    history.push(paths.auth.path);
    return <Page403 />;
  }

  // TODO: [KONG] Extract navigation logic
  const userInformation = JSON.parse(value);
  let navigation: NavigationLink[] = [];

  const navigationPublicHealthProviderAgent: NavigationLink[] = [
    {
      name: t('admin.navigation.policies.title', 'Policies'),
      icon: BriefcaseIcon,
      id: 'policies',
      children: [
        ...(userInformation?.company
          ? [
              {
                name: t(
                  'admin.navigation.policies.public.title',
                  'Public health'
                ),
                href: generatePath(paths.policies.publicHealth.provider.path, {
                  provider: userInformation.company.toLowerCase(),
                }),
                id: 'policies-public',
              },
            ]
          : []),
      ],
    },
  ];

  const navigationHR: NavigationLink[] = [
    {
      name: t('admin.navigation.policies.title', 'Policies'),
      icon: BriefcaseIcon,
      id: 'policies',
      children: [
        ...(userInformation?.company
          ? [
              {
                name: t(
                  'admin.navigation.hr.policies.public.title',
                  'Public health'
                ),
                href: retrieveHRPoliciesOverviewPath(
                  'public-health',
                  'de',
                  userInformation.company.toLowerCase().replace(' ', '-')
                ),
                id: 'policies-public-health',
              },
              ...(isPrivateHealthHrEnabled
                ? [
                    {
                      name: t(
                        'admin.navigation.hr.policies.private.title',
                        'Private health'
                      ),
                      href: retrieveHRPoliciesOverviewPath(
                        'private-health',
                        'de',
                        userInformation.company.toLowerCase().replace(' ', '-')
                      ),
                      id: 'policies-private-health',
                    },
                  ]
                : []),
              {
                name: t(
                  'admin.navigation.hr.policies.companyPension.title',
                  'Company pension'
                ),
                href: retrieveHRPoliciesOverviewPath(
                  'company-pension',
                  'de',
                  userInformation.company.toLowerCase().replace(' ', '-')
                ),
                id: 'policies-company-pension',
              },
              {
                name: t(
                  'admin.navigation.hr.policies.companyHealth.title',
                  'Company health'
                ),
                href: retrieveHRPoliciesOverviewPath(
                  'company-health',
                  'de',
                  userInformation.company.toLowerCase().replace(' ', '-')
                ),
                id: 'policies-company-health',
              },
              {
                name: t(
                  'admin.navigation.hr.policies.companyLife.title',
                  'Company life'
                ),
                href: retrieveHRPoliciesOverviewPath(
                  'company-life',
                  'de',
                  userInformation.company.toLowerCase().replace(' ', '-')
                ),
                id: 'policies-company-life',
              },
            ]
          : []),
      ],
    },
  ];

  if (
    userInformation.roles.includes('PUBLIC_HEALTH_PROVIDER_AGENT') ||
    userInformation.roles.includes('PUBLIC_HEALTH_PROVIDER_ADMIN') ||
    userInformation.roles.includes('PUBLIC_HEALTH_PROVIDER_AGENT_PLUS')
  ) {
    navigation = navigation.concat(navigationPublicHealthProviderAgent);
  }

  if (userInformation.roles.includes('HR_PARTNER')) {
    navigation = navigation.concat(navigationHR);
  }

  if (userInformation.roles.includes('FEATHER_ADMIN')) {
    const defaultRegionalClaimsLinks = createRegionalClaimsLinks(country);

    switch (country) {
      case 'de':
        navigation = navigation.concat(
          customersLinks,
          germanyPoliciesLinks,
          germanyClaimsLinks,
          germanyClaimAssessmentsLinks,
          issuesLinks,
          paymentsLinks,
          recordsLinksDE,
          adminLinks
        );
        break;
      case 'es':
        navigation = navigation.concat(
          customersLinks,
          spainPoliciesLinks,
          spainClaimsLink,
          spainPaymentsLinks,
          recordsLinks,
          adminLinks
        );
        break;
      case 'fr':
        navigation = navigation.concat(
          customersLinks,
          francePoliciesLinks,
          defaultRegionalClaimsLinks,
          recordsLinks,
          adminLinks
        );
        break;
      default: {
        navigation = navigation.concat(
          customersLinks,
          regionPolicyLinksMapper[country],
          defaultRegionalClaimsLinks,
          recordsLinks,
          adminLinks
        );
        break;
      }
    }
  }

  return (
    <div
      className={classNames(styles.navbarWrapper, {
        'min-h-[80px]': !open,
        'min-h-[450px]': open,
      })}
    >
      <div className="relative z-20 flex flex-col bg-indigo-700 md:h-full">
        <div className={styles.logoWrapper}>
          <Logo className={styles.logo} color="white" />
          <button
            type="button"
            onClick={() => setOpen(!open)}
            className="block md:hidden"
          >
            <MenuIcon className={styles.menuIcon} />
          </button>
        </div>
        <div
          className={classNames('md:block', {
            hidden: !open,
            block: open,
          })}
        >
          {userInformation.roles.includes('FEATHER_ADMIN') && (
            <div className="mx-2 mt-6">
              <SelectMenu
                options={countryOptions}
                selected={
                  countryOptions.find((option) => option.id === country) ??
                  countryOptions[0]
                }
                setSelected={(option: SelectMenuOption) => {
                  setCountry(option.id as Country);
                }}
                disabled={false}
                withDot={false}
                label=""
                placeholder=""
                className="w-full"
                color="dark"
              />
            </div>
          )}
        </div>
        <div
          className={classNames('basis-2 grow md:flex', {
            hidden: !open,
            flex: open,
          })}
        >
          <div className={styles.linkSectionWrapper}>
            <nav className={styles.linkSection} aria-label="Sidebar">
              {navigation.map((item) =>
                !item.children ? (
                  <div key={item.id}>
                    <a
                      href={item.href}
                      className={classNames(
                        item.id === current
                          ? styles.linkCurrent
                          : styles.linkNotCurrent,
                        styles.link
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.id === current
                            ? styles.linkIconCurrent
                            : styles.linkIconNotCurrent,
                          styles.linkIcon
                        )}
                      />
                      {item.name}
                    </a>
                  </div>
                ) : (
                  <Disclosure
                    as="div"
                    key={item.id}
                    className={styles.linkGroup}
                    defaultOpen={
                      !!item.children.find((child) => child.id === current)
                    }
                  >
                    {({ open }) => (
                      <>
                        <Disclosure.Button className={styles.linkGroupButton}>
                          <item.icon className={styles.linkGroupIcon} />
                          <span className={styles.linkGroupName}>
                            {item.name}
                          </span>
                          <svg
                            className={classNames(styles.linkGroupArrow, {
                              'rotate-90': open,
                            })}
                            viewBox="0 0 20 20"
                          >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className={styles.linkGroupChildren}>
                          {item.children?.map((subItem) => (
                            <Disclosure.Button
                              key={subItem.name}
                              as="a"
                              href={subItem.href}
                              className={classNames(styles.linkGroupChild, {
                                [styles.linkGroupChildCurrent]:
                                  subItem.id === current,
                              })}
                            >
                              {subItem.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              )}
            </nav>
          </div>
        </div>
        <div
          className={classNames('sticky bottom-0 grow-0 md:flex', {
            flex: open,
            hidden: !open,
          })}
        >
          <div className={styles.footerWrapper}>
            <div className={styles.userSection}>
              <UserIcon className={styles.userIcon} />
              <p
                className={styles.userName}
              >{`${userInformation.firstName} ${userInformation.lastName}`}</p>
            </div>
            <a href={paths.auth.logOut.path}>
              <LogoutIcon className={styles.logoutIcon} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
