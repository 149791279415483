import { DocumentNode } from '@apollo/client';
import { ExternalLink, InternalLink } from 'components/DetailsPage/models';
import { InformationSection } from 'components/InformationCard';
import { FilterConfig } from 'components/TableRefactor/Filters/types';
import {
  HeaderConfig,
  RowConfig,
  ViewConfig,
} from 'components/TableRefactor/types';
import { Country } from 'shared/countries/models';
import { z, ZodRawShape } from 'zod';

export const NUMBER_OF_CLAIM_ASSESSMENTS_PER_PAGE = 12;

export interface ClaimAssessmentsTemplateData {
  region?: Country;
  queries: {
    fetchAllClaimAssessments: DocumentNode;
    fetchAllPolicies: DocumentNode;
  };
  mutations: {
    createClaimAssessment?: DocumentNode;
    updateClaimAssessment?: DocumentNode;
  };
  overview: {
    headers: HeaderConfig[];
    filterConfig: FilterConfig[];
    rowConfig: RowConfig;
    viewConfig?: ViewConfig;
  };
  claimAssessmentCreation?: ClaimAssessmentCreationData;
  claimAssessmentDetails?: ClaimAssessmentDetails;
  claimAssessmentInfo?: ClaimAssessmentInfo;
}

export interface ClaimAssessmentCreationData {
  createClaimAssessmentSubmissionSchema: z.ZodObject<ZodRawShape>;
}

export interface ClaimAssessmentDetails {
  externalLinks?: ExternalLink[];
  internalLinks?: InternalLink[];
}

export interface ClaimAssessmentInfo {
  retrieveClaimAssessmentInfoRows: (
    claimAssessmentInfo: any
  ) => InformationSection[];
}
